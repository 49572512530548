export const TOOLBAR_BUTTONS_FROALA = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'fontSize',
  'textColor',
  'insertTable',
  'align',
  'formatOL',
  'formatUL',
  'insertHR',
  'insertLink',
  'emoticons',
  'insertImage',
  'insertVideo',
  'html'
];

export const TOOLBAR_BUTTONS_FROALA_TERM_FORM = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'fontSize',
  'textColor',
  'align',
  'formatOL',
  'formatUL',
  'insertHR',
  'insertLink'
];

export const TOOLBAR_BUTTONS_FROALA_ONLY_WRITING = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'fontSize',
  'textColor',
  'align',
  'formatOL'
];

export const TOOLBAR_BUTTONS_FROALA_TEXT_AND_IMAGE = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'fontSize',
  'textColor',
  'align',
  'formatOL',
  'insertImage'
];

export const TOOLBAR_BUTTON_TERMS = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'fontSize',
  'textColor',
  'align',
  'formatOL'
];

export const PLUGINS_ENABLED_FROALA = [
  'align',
  'charCounter',
  'codeBeautifier',
  'codeView',
  'colors',
  'draggable',
  'embedly',
  'emoticons',
  'entities',
  'file',
  'fontAwesome',
  'fontFamily',
  'fontSize',
  'fullscreen',
  'image',
  'imageTUI',
  'imageManager',
  'inlineStyle',
  'inlineClass',
  'lineBreaker',
  'lineHeight',
  'link',
  'lists',
  'paragraphFormat',
  'paragraphStyle',
  'quickInsert',
  'quote',
  'save',
  'table',
  'url',
  'video',
  'wordPaste'
];

export const FROALA_OPEN_FULLSCREEN_ID = 'preview-editor-fullscreen';
export const TOOLBAR_BUTTON_INSERT_IMAGE = 'insertImage';
export const TOOLBAR_BUTTON_INSERT_VIDEO = 'insertVideo';
