<template>
  <div
    :id="uuid"
    ref="containerRef"
    class="relative studio-froala-editor text-editor-froala-custom"
    :class="`${disabled || readonly ? ' studio-froala-editor-disabled' : ''}`"
  >
    <froala
      :id="`editor_${uuid}`"
      ref="froalaEditor"
      v-model:value="model"
      :tag="tag ?? 'textarea'"
      :config="defaultConfig"
      :class="textEditorClass"
      :name="fieldName"
      spellcheck="false"
      @update:value="handleUpdate"
    />
    <st-error-message
      :name="fieldName"
      :showError="isErrorShown"
      :data="{ length: wordCountLimit }"
      :class="{ 'absolute bottom-[-2.2rem] left-0': absoluteErrorMsg }"
    />
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed, onUnmounted, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { uploadFileApi } from '@/apis/file.api';
import StorageUpload from '@/components/storage/storage-upload.vue';
import StErrorMessage from '@/components/validation/st-error-message.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import {
  ARRAY_IMAGE_FILE_EXTENSION,
  ARRAY_VIDEO_FILE_EXTENSION,
  MAP_FILE_TYPE_TO_MINE_TYPE
} from '@/constants/file.const';
import {
  PLUGINS_ENABLED_FROALA,
  TOOLBAR_BUTTON_INSERT_IMAGE,
  TOOLBAR_BUTTON_INSERT_VIDEO
} from '@/constants/froala.const';
import type { TextEditorProps } from '@/types/common/form.type';
import type { StorageUploadPopupProps } from '@/types/common/popup.type';
import type { StorageUploadFileType } from '@/types/storage-upload/storage-upload-file.type';
import type { ValidationRule } from '@/types/validation.type';
import { getConfigs } from '@/utils/common.util';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { checkFileNameMaxLength } from '@/utils/file.util';
import { generateUUID } from '@/utils/uuid.util';

const { FROALA_KEY } = getConfigs();

const { t } = useI18n();
const route = useRoute();
const routeProjectId = route.params.projectId as string;

interface InitializedEditorEvent {
  (editor?: any): void;
}

const props = withDefaults(defineProps<TextEditorProps>(), {
  showError: true,
  absoluteErrorMsg: false,
  isChooseOneFileFromStorage: true,
  isSingleEditor: false
});

const emits = defineEmits<{
  'update:modelValue': [v: string];
  wordCount: [v: number];
  onClickBtnHtmlCodeBlock: [v: boolean];
}>();

const setFieldValue = ref<(value: string) => void>();
const editor = ref<any>();
const model = ref(props.modelValue);

const uuid: string = `editor_${generateUUID()}`;
const containerRef = ref();
const froalaEditor = ref();
const isValid = ref<boolean>(false);

let insertVideo: HTMLElement | null;
let storageUploadVideo: HTMLElement | null;
let insertImage: HTMLElement | null;
let storageUploadImage: HTMLElement | null;
let inputImageComputer: HTMLElement | null;
let inputVideoComputer: HTMLElement | null;

const fieldName = computed<string>(() => props.name ?? '');
const isErrorShown = computed(() => props.showError);
const CMDS_NEED_TO_RE_POSITION = ['insertLink', 'emoticons', 'textColor', 'insertTable'];

const validationRef = ref(props.rules);

if (fieldName.value) {
  watch(
    () => props.rules,
    (newValue: ValidationRule | undefined) => {
      validationRef.value = newValue;
    }
  );
  const { value: fieldValue, setValue, errors } = useField<string>(fieldName.value, validationRef);

  setFieldValue.value = setValue;

  const toggleClass = (mode: 'add' | 'remove') => {
    const editorElement = document.querySelector(`#${uuid} > .fr-box.fr-basic.fr-top`);
    const errorClasses: string[] = []; // ['border-error', 'border-2', 'border-solid'];
    errorClasses.forEach((errorClass: string) => {
      if (!editorElement) {
        return;
      }
      editorElement.classList[mode](errorClass);
    });
  };

  watch(
    () => fieldValue.value,
    (_v: string) => {
      // model.value = _v ?? '';
    },
    { immediate: true, deep: true }
  );

  watch(
    () => isErrorShown.value,
    (value: boolean) => {
      if (value && !isValid.value) {
        toggleClass('add');
      } else {
        toggleClass('remove');
      }
    }
  );

  watch(
    () => errors.value,
    (value: string[]) => {
      if (value && value.length) {
        isValid.value = false;

        if (isErrorShown.value) {
          toggleClass('add');
        }
      } else {
        isValid.value = true;

        if (isErrorShown.value) {
          toggleClass('remove');
        }
      }
    },
    { immediate: true }
  );
}

const handleUpdate = () => {
  if (props.readonly) {
    editor.value.commands.undo();
    return;
  }

  // Set only value (no HTML tags) to form to validate
  if (setFieldValue.value) {
    editor.value.selection.save();
    editor.value.commands.selectAll();
    setFieldValue.value(editor.value.selection.text());
    editor.value.selection.clear();
    editor.value.selection.restore();
  }

  emits('wordCount', editor.value?.charCounter?.count());
  emits('update:modelValue', model.value || '');
};

const handleEventClickStorageUploadVideo = async () => {
  const propsStorage: StorageUploadPopupProps = {
    projectId: (props?.projectId as string) || routeProjectId,
    isAttachedFile: true,
    isChooseOneFile: props.isChooseOneFileFromStorage,
    uploadOptions: {
      accept: ARRAY_VIDEO_FILE_EXTENSION
    }
  };
  const results: StorageUploadFileType[] = await showDialog({
    component: shallowRef(StorageUpload),
    props: {
      ...propsStorage
    }
  });
  const selection = editor.value.selection.get();
  if (results?.length && selection && selection.rangeCount > 0) {
    results.forEach((item: StorageUploadFileType) => {
      const videoHTML = `
            <span contenteditable="false" draggable="true" class="fr-video fr-dvb fr-draggable fr-active">
              <video controls style="width: 600px;" class="fr-draggable">
                <source src="${item.linkCdn}" type="${MAP_FILE_TYPE_TO_MINE_TYPE[item.fileType]}">
                Your browser does not support the video tag.
              </video>
            </span>
          `;
      editor.value.html.insert(videoHTML);
    });
  }
};

const handleEventClickUploadComputerVideo = async (event: any) => {
  event.preventDefault();
  event.stopPropagation();
  const file = event.target.files[0];
  if (file) {
    if (!(await checkFileNameMaxLength(file.name))) {
      return;
    }
    if (props.uploadVideoComputerRules) {
      if (
        props.uploadVideoComputerRules.maxFileSize &&
        file.size > props.uploadVideoComputerRules.maxFileSize
      ) {
        await showAlert({
          content: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert4')
        });
        return;
      }
      if (
        props.uploadVideoComputerRules.allowedExtensions &&
        !ARRAY_VIDEO_FILE_EXTENSION.includes(file.type)
      ) {
        await showAlert({
          content: t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert3')
        });
        return;
      }
    }

    const results = await uploadFileApi([file], 'video');
    const selection = editor.value.selection.get();

    if (results?.length && selection && selection.rangeCount > 0) {
      const item = results[0];
      const videoHTML = `
          <span contenteditable="false" draggable="true" class="fr-video fr-dvb fr-draggable fr-active">
            <video controls style="width: 600px;" class="fr-draggable">
              <source src="${item.linkCdn}" type="${item.filetype}">
              Your browser does not support the video tag.
            </video>
          </span>
        `;
      editor.value.html.insert(videoHTML);
      event.target.value = '';
    }
  }
};

const handleEventClickInsertVideo = () => {
  const videoUpload = editor.value.rootElement?.querySelector('button[data-cmd="videoUpload"]');
  const uploadLayer = editor.value.rootElement?.querySelector('.fr-video-upload-layer');
  const urlLayer = editor.value.rootElement?.querySelector('.fr-video-by-url-layer');
  const embedLayer = editor.value.rootElement?.querySelector('.fr-video-embed-layer');
  const frImageProgressBarLayer = editor.value.rootElement.querySelector(
    '.fr-video-progress-bar-layer'
  );
  const containerPopupImage = videoUpload?.parentElement as HTMLElement;
  const containerContentPopup = uploadLayer?.parentElement as HTMLElement;
  if (containerContentPopup) {
    const currentLeft = insertVideo?.offsetLeft.toString() ?? containerContentPopup?.style.left;
    containerContentPopup.style.left = currentLeft + 'px';
    containerContentPopup.innerHTML = '';
  }
  uploadLayer?.remove();
  containerPopupImage?.remove();
  urlLayer?.remove();
  embedLayer?.remove();
  frImageProgressBarLayer?.remove();
  const el = `<div class="fr-video-upload-layer fr-layer fr-active custom-btn-upload" data-mouseenter-event-set="true">
        <div class="relative button-hover">
          <button class="w-full outline-0 p-[.6rem] relative" data-title="Upload Video" id="my-btn-upload">
            <span>${t('studio.prj_prod.this_prod.edit_summary_attatch_msg1')}</span>
            <div class="fr-form">
              <input type="file" accept="video/mp4, video/webm" tabindex="-1" role="button" dir="auto" id="input-video-computer">
            </div>
          </button>
        </div>
        <div class="mt-[1rem] border-b-1 w-full" style="height: 1px"></div>
        ${
  props.projectId || routeProjectId
    ? `<div class="relative button-hover">
            <button id="wrap_video_manager_${
  editor.value.uuid
}" class="w-full outline-0 p-[.6rem] relative">
              <span>${t('studio.prj_prod.this_prod.edit_summary_attatch_msg2')}</span>
              <button class="fr-command fr-btn !absolute top-0 left-0 w-full h-full opacity-0" id="video_manager_${
  editor.value.uuid
}" type="button" tabindex="-1" role="button"></button>
            </button>
          </div>`
    : ''
}
        </div>`;
  containerContentPopup?.insertAdjacentHTML('beforeend', el);
  // Add video from computer
  if (containerRef.value) {
    if (props.isSingleEditor) {
      inputVideoComputer = document.getElementById('input-video-computer');
    } else {
      inputVideoComputer = containerRef.value.querySelector('#input-video-computer');
    }
    inputVideoComputer?.addEventListener('change', handleEventClickUploadComputerVideo);
  }
  // Add storage upload modal
  if (props.projectId || routeProjectId) {
    storageUploadVideo = containerRef.value.querySelector(`#video_manager_${editor.value.uuid}`);
    storageUploadVideo?.addEventListener('click', handleEventClickStorageUploadVideo);
  }
};

const initCustomButtonInsertVideo = (editor: any) => {
  // Change the default video upload button to a custom button
  insertVideo = editor.rootElement?.querySelector('button[data-cmd="insertVideo"]');
  insertVideo?.addEventListener('click', handleEventClickInsertVideo);
  // if (props.projectId || routeProjectId) {
  // insertVideo?.addEventListener('click', handleEventClickInsertVideo);
  // }
  // else {
  //   insertVideo?.remove();
  // }
};

const handleEventClickStorageUploadImage = async () => {
  const propsStorage: StorageUploadPopupProps = {
    projectId: (props?.projectId as string) || routeProjectId,
    isAttachedFile: true,
    isChooseOneFile: props.isChooseOneFileFromStorage,
    uploadOptions: {
      accept: ARRAY_IMAGE_FILE_EXTENSION
    }
  };
  const results: StorageUploadFileType[] = await showDialog({
    component: shallowRef(StorageUpload),
    props: {
      ...propsStorage
    }
  });
  if (results?.length) {
    results.forEach((item: StorageUploadFileType) => {
      editor.value.image.insert(item.linkCdn, false, { alt: item.userFileNames });
    });
  }
};

const handleEventClickUploadComputerImage = async (event: any) => {
  event.preventDefault();
  event.stopPropagation();
  const file = event.target.files[0];
  if (file) {
    if (!(await checkFileNameMaxLength(file.name))) {
      return;
    }
    const results = await uploadFileApi([file], 'image', true);
    const selection = editor.value.selection.get();
    if (results?.length && selection && selection.rangeCount > 0) {
      const image = results[0];
      editor.value.image.insert(image.linkCdn, false, { alt: image.fileName });
      event.target.value = '';
    }
  }
};

const handleEventClickInsertImage = () => {
  const imageUpload = editor.value.rootElement?.querySelector('button[data-cmd="imageUpload"]');
  const uploadLayer = editor.value.rootElement?.querySelector('.fr-image-upload-layer');
  const frImageProgressBarLayer = editor.value.rootElement?.querySelector(
    '.fr-image-progress-bar-layer'
  );
  const containerPopupImage = imageUpload?.parentElement as HTMLElement;
  const containerContentPopup = uploadLayer?.parentElement as HTMLElement;
  if (containerContentPopup) {
    const currentLeft = insertImage?.offsetLeft.toString() ?? containerContentPopup?.style.left;
    containerContentPopup.style.left = currentLeft + 'px';
    containerContentPopup.innerHTML = '';
  }
  containerPopupImage?.remove();
  uploadLayer?.remove();
  frImageProgressBarLayer?.classList.add('hidden');
  const el = `<div class="fr-image-upload-layer fr-active fr-layer custom-btn-upload" data-mouseenter-event-set="true">
          <div class="relative button-hover">
            <button class="w-full outline-0 p-[.6rem] relative" data-title="Upload Video" id="my-btn-upload">
              <span>${t('studio.prj_prod.this_prod.edit_summary_attatch_msg1')}</span>
              <div class="fr-form">
                <input type="file" accept="image/jpeg, image/jpg, image/png, image/gif, image/webp" tabindex="-1" role="button" dir="auto" id="input-image-computer">
              </div>
            </button>
          </div>
          <div class="mt-[1rem] border-b-1 w-full" style="height: 1px"></div>
          ${
  props.projectId || routeProjectId
    ? `<div class="relative button-hover">
            <button id="wrap_image_manager_${
  editor.value.uuid
}" class="w-full outline-0 p-[.6rem] relative">
              <span>${t('studio.prj_prod.this_prod.edit_summary_attatch_msg2')}</span>
              <button class="fr-command fr-btn !absolute top-0 left-0 w-full h-full opacity-0" id="image_manager_${
  editor.value.uuid
}" type="button" tabindex="-1" role="button"></button>
            </button>
          </div>`
    : ''
}
</div>`;
  containerContentPopup?.insertAdjacentHTML('beforeend', el);
  // Add image from computer
  if (containerRef.value) {
    if (props.isSingleEditor) {
      inputImageComputer = document.getElementById('input-image-computer');
    } else {
      inputImageComputer = containerRef.value.querySelector('#input-image-computer');
    }
    inputImageComputer?.addEventListener('change', handleEventClickUploadComputerImage);
    // Add storage upload modal
    storageUploadImage = containerRef.value.querySelector(`#image_manager_${editor.value.uuid}`);
    storageUploadImage?.addEventListener('click', handleEventClickStorageUploadImage);
  }
};

const initCustomButtonInsertImage = (editor: any) => {
  // Change the default image upload button to a custom button
  insertImage = editor.rootElement?.querySelector('button[data-cmd="insertImage"]');
  insertImage?.addEventListener('click', handleEventClickInsertImage);
  // if (props.projectId || routeProjectId) {
  //   insertImage?.addEventListener('click', handleEventClickInsertImage);
  // } else {
  //   insertImage?.remove();
  // }
};

const initCustomToolbarCounterCharacter = (editor: any) => {
  if (!props.hiddenSecondToolbar) {
    const secondToolbarWCounter = editor.rootElement?.querySelector('.fr-counter.fr-wCounter');
    const secondToolbarCounter = editor.rootElement?.querySelector('.fr-counter');
    secondToolbarWCounter?.classList.add('hidden');
    secondToolbarCounter?.classList.add('hidden');
    if (secondToolbarCounter) {
      const secondToolbar = secondToolbarCounter?.parentElement;
      const textMaxLength =
        props.charCounterMax && props.charCounterMax > 0
          ? `/${formatNumberMultipleWithCommas(props?.charCounterMax)}`
          : '';
      const formatNumber = formatNumberMultipleWithCommas(editor.charCounter.count());
      secondToolbar?.insertAdjacentHTML(
        'beforeend',
        `<span class="my-counter">${
          editor.charCounter.count()
            ? `<b class="text-on-surface-elevation-2">${formatNumber}</b>`
            : `<b>${formatNumber}</b>`
        }${textMaxLength}</span>`
      );
      const myCounter = secondToolbar?.querySelector('.my-counter');
      const observer = new MutationObserver((_mutations: MutationRecord[]) => {
        const formatNumberAfterChange = formatNumberMultipleWithCommas(editor.charCounter.count());
        if (myCounter?.innerHTML) {
          const elementCounter =
            props?.charCounterMax && editor.charCounter.count() > props?.charCounterMax
              ? `<span class="text-error">${formatNumberAfterChange}</span>`
              : formatNumberAfterChange;
          myCounter!.innerHTML = `${
            editor.charCounter.count()
              ? `<b class="text-on-surface-elevation-2">${elementCounter}</b>`
              : `<b>${formatNumberAfterChange}</b>`
          }${textMaxLength}`;
        }
      });
      observer.observe(secondToolbarCounter, {
        characterData: true,
        childList: true,
        subtree: true
      });
    }
  } else {
    const secondToolbarCounter = editor.rootElement?.querySelector('.fr-counter');
    const secondToolbar = secondToolbarCounter?.parentElement;
    secondToolbar?.classList.add('hidden');
  }
};

const initNewPositionTopOfDropdownToolbar = () => {
  function handleAttributeChange(element: HTMLElement): void {
    if (element.getAttribute('aria-hidden') === 'false') {
      const currentTop: string = window.getComputedStyle(element).top;
      const newTop: number = parseFloat(currentTop) + 12;
      element.style.top = `${newTop}px`;
    }
  }
  // eslint-disable-next-line no-undef
  const elements: NodeListOf<HTMLElement> = document.querySelectorAll(
    '.fr-toolbar .fr-dropdown-menu'
  );
  elements.forEach((element: HTMLElement) => {
    const observer: MutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'aria-hidden') {
          handleAttributeChange(element);
        }
      });
    });
    const config = { attributes: true, attributeFilter: ['aria-hidden'] };
    observer.observe(element, config);
  });
};

const initDisabledEditor = (editor: any) => {
  if (props.disabled || props.readonly) {
    editor.edit.off();
  } else {
    editor.edit.on();
  }

  if (!props.readonly) {
    return;
  }

  const editorDomElement = document.querySelector(`#${uuid} .fr-element.fr-view.fr-disabled `);
  if (editorDomElement) {
    editorDomElement.classList.remove('fr-disabled');
  }
};

let btnToggleHtmlCodeBlock: HTMLElement | null;

const handleEventClickBtnHtmlCodeBlock = () => {
  emits('onClickBtnHtmlCodeBlock', editor.value.codeView.isActive());
};

const initBtnHtmlCodeBlock = () => {
  btnToggleHtmlCodeBlock = document.querySelector(`#${uuid} button[data-cmd="html"]`);
  btnToggleHtmlCodeBlock?.addEventListener('click', handleEventClickBtnHtmlCodeBlock);
};

const updateCmdPopupPosition = () => {
  const insertLinkPopup = document.getElementsByClassName(
    'fr-popup fr-desktop fr-active'
  )[0] as HTMLElement;
  if (insertLinkPopup) {
    const parentElement = insertLinkPopup.parentElement;
    const popupWidth = insertLinkPopup.offsetWidth;
    if (parentElement) {
      let left = -1;
      const parrentWidth = parentElement.offsetWidth;
      if (popupWidth < parrentWidth && insertLinkPopup.offsetLeft + popupWidth > parrentWidth) {
        left = parrentWidth - popupWidth;
      } else if (popupWidth > parrentWidth) {
        left = Math.round((parentElement.offsetWidth - popupWidth) / 2);
      } else if (popupWidth === parrentWidth) {
        left = 0;
      }
      if (left !== -1) {
        insertLinkPopup.style.left = left + 'px';
      }
    }
  }
};

const defaultConfig = computed(() => {
  return {
    useClasses: true,
    // imageDefaultDisplay: 'block',
    key: FROALA_KEY,
    toolbarButtons: props.toolbarButtons || [],
    attribution: false,
    charCounterMax: props.charCounterMax,
    quickInsertTags: [],
    pastePlain: true,
    toolbarSticky: false,
    imageEditButtons: [
      'imageAlign',
      'imageCaption',
      'imageRemove',
      'imageLink',
      'linkOpen',
      'linkEdit',
      'linkRemove',
      '-',
      'imageDisplay',
      'imageStyle',
      'imageAlt',
      'imageSize'
    ],
    videoEditButtons: ['videoRemove', 'videoSize', 'autoplay'],
    emoticonsUseImage: false,
    ...props.config,
    // htmlDoNotWrapTags: [],
    spellcheck: false,
    imagePaste: false,
    pluginsEnabled: PLUGINS_ENABLED_FROALA,
    linkList: [],
    events: {
      initialized: function() {
        editor.value = this;
        editor.value.uuid = containerRef.value.id;
        editor.value.rootElement = containerRef.value;

        initCustomToolbarCounterCharacter(this);
        initNewPositionTopOfDropdownToolbar();
        initDisabledEditor(this);
        initBtnHtmlCodeBlock();
        if (props.toolbarButtons && props.toolbarButtons.includes(TOOLBAR_BUTTON_INSERT_IMAGE)) {
          initCustomButtonInsertImage(this);
        }
        if (props.toolbarButtons && props.toolbarButtons.includes(TOOLBAR_BUTTON_INSERT_VIDEO)) {
          initCustomButtonInsertVideo(this);
        }

        // Call events initialized from custom config
        if (props.config?.events?.initialized) {
          props.config.events.initialized.bind(this)();
        }

        props.initializedEditor?.forEach((event: InitializedEditorEvent) => {
          // If the function has a parameter, pass the editor value to it
          if (event.length !== 0) {
            event(editor.value);

            return;
          }

          event();
        });

        emits('wordCount', editor.value?.charCounter?.count());
      },
      'commands.after': function(cmd: string, val: string) {
        if (cmd === 'align') {
          const toolbar = editor.value.$tb;
          const alignButton = toolbar.find('.fr-command[data-cmd="align"]')[0] as HTMLElement;
          if (alignButton) {
            alignButton.setAttribute('data-value', val.trim());
          }
        } else if (CMDS_NEED_TO_RE_POSITION.includes(cmd)) {
          updateCmdPopupPosition();
        }
      }
    }
  };
});

const hideAllPopups = () => {
  editor.value.popups.hideAll();
};

watch(
  () => props.modelValue,
  (value: string | undefined) => {
    model.value = value;
  }
);

onUnmounted(() => {
  insertVideo?.removeEventListener('click', handleEventClickInsertVideo);
  storageUploadVideo?.removeEventListener('click', handleEventClickStorageUploadVideo);
  insertImage?.removeEventListener('click', handleEventClickInsertImage);
  storageUploadImage?.removeEventListener('click', handleEventClickStorageUploadImage);
  inputVideoComputer?.removeEventListener('change', handleEventClickUploadComputerVideo);
  inputImageComputer?.removeEventListener('change', handleEventClickUploadComputerImage);
  btnToggleHtmlCodeBlock?.removeEventListener('click', handleEventClickBtnHtmlCodeBlock);

  const tuiContainerEl = document.querySelector('#tuiContainer');
  if (tuiContainerEl) {
    tuiContainerEl.remove();
  }
});

defineExpose({
  hideAllPopups
});
</script>

<style lang="scss" src="@@/assets/css/froala-editor.scss"></style>
<style lang="scss">
.text-editor-froala-custom {
  em {
    font-style: italic !important;
  }

  .fr-box.fr-code-view textarea.fr-code {
    height: 100% !important;
  }
}

.stove-studio {
  button[data-cmd='linkList'] {
    display: none !important;
  }

  button[data-cmd='linkStyle'] {
    display: none !important;
  }

  button[data-cmd='formatOLOptions'] {
    display: none !important;
  }

  button[data-cmd='formatULOptions'] {
    display: none !important;
  }
}

// .fr-image-progress-bar-layer {
//display: none !important;
// }
</style>
